import React from 'react'
import { Container, Row } from 'react-bootstrap'
import AchievementCard from '../homepage/AchievementCard'

const WorkStrategy = ({data}) => {
    return (
        <>
            <Container>
                <Row className="strategy-section d-none d-md-flex d-lg-flex d-xl-flex">
                    {
                        data && data.map((strategy, index) => (
                            <AchievementCard 
                                colClass="col-md-3 col-sm-6 col-xs-12"
                                key={index + 'workstrategy'}
                                className="strategy-card"
                                readMore={strategy.readMore}
                                number={strategy.number}
                                heading={strategy.heading}
                                description={strategy.description} />
                        ))
                    }
                </Row>
               
                    {
                        data && data.map((strategy, index) => (
                            <Row className="strategy-section d-md-none d-xl-none d-lg-none px-3">
                            <AchievementCard 
                                colClass="col-md-3 col-sm-6 col-xs-12"
                                key={index + 'workstrategy'}
                                className="strategy-card"
                                number={strategy.number}
                                readMore={strategy.readMore}
                                heading={strategy.heading}
                                description={strategy.description} />
                                 </Row>
                        ))
                    }
               
            </Container>
        </>
    );
};

export default WorkStrategy